<template>
  <v-layout column>
    <ir-invoice-charges-search @search="getInvoiceCharges" />

    <vbt-content-box
      :loading="loading"
      :loading-error="loadingError"
      :no-content="!invoiceCharges.length"
      title="Invoice Charges"
    >
      <ir-invoice-charges-list :items="invoiceCharges" />

      <vbt-pagination
        v-model="pageNum"
        :items-count="pagedItemsCount"
      />
    </vbt-content-box>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex';

import { wrapToLoadingFn } from '@helpers';

import { usePagination } from '@mixins/factories';

import { dataLoadingState } from '@mixins';

import { InvoiceChargesApiService } from '../_services';

import { IrInvoiceChargesSearch, IrInvoiceChargesList } from '../_components';

const paginationOptions = { itemsName: 'invoiceCharges' };

export default {
  name: 'IrInvoiceCharges',

  components: {
    IrInvoiceChargesSearch,
    IrInvoiceChargesList,
  },

  mixins: [
    usePagination(paginationOptions),
    dataLoadingState,
  ],

  data() {
    return {
      invoiceCharges: [],
    };
  },

  created() {
    this.getAllAccounts();
  },

  methods: {
    ...mapActions('accounts', ['getAllAccounts']),

    getInvoiceCharges(params) {
      this.$_setDataLoadingPending();

      this.wrapToLoadingFn({
        req: InvoiceChargesApiService.getInvoiceCharges.bind({}, {
          ...params,
          startDate: params.dates ? params.dates[0] : null,
          endDate: params.dates ? `${params.dates[1]} 23:59:59` : null,
        }),
        loadingFlagName: dataLoadingState.$_meta.LOADING_FLAG_NAME,
        onSuccess: response => this.$set(this, 'invoiceCharges', response),
        onReject: e => this.$_setDataLoadingError(e),
      });
    },

    wrapToLoadingFn,
  },
};
</script>
