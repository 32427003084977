var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vbt-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"page-size":_vm.pageSize},scopedSlots:_vm._u([{key:"orderId",fn:function(ref){
var id = ref.item.orderId;
return [(id)?_c('router-link',{staticClass:"blue--text darken-1",attrs:{"to":{
          name: 'order',
          params: { id: id },
        }}},[_vm._v(" "+_vm._s(id)+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"accountId",fn:function(ref){
        var accountId = ref.item.accountId;
return [_vm._v(" "+_vm._s(_vm.accountNameByIdFromAllAccounts[accountId])+" ")]}},{key:"quantity",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.quantity) || '-')+" ")]}},{key:"dateCreated",fn:function(ref){
        var dateCreated = ref.item.dateCreated;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(dateCreated))+" ")]}},{key:"dateReleased",fn:function(ref){
        var dateReleased = ref.item.dateReleased;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(dateReleased))+" ")]}},{key:"amount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.amount))+" / "+_vm._s(_vm.formatNumber(item.taxAmount))+" / "+_vm._s(_vm.formatNumber(item.amountWithTax))+" "+_vm._s((" " + (item.currencyCode)))+" ")]}},{key:"actions",fn:function(ref){
        var metadata = ref.item.metadata;
return [_c('ir-invoice-charge-metadata',{attrs:{"metadata":metadata}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }