<template>
  <vbt-table
    :items="items"
    :headers="headers"
    :page-size="pageSize"
  >
    <template #orderId="{ item: { orderId: id } }">
      <router-link
        v-if="id"
        :to="{
            name: 'order',
            params: { id },
          }"
        class="blue--text darken-1"
      >
        {{ id }}
      </router-link>
      <span v-else>
        -
      </span>
    </template>

    <template #accountId="{ item: { accountId } }">
      {{ accountNameByIdFromAllAccounts[accountId] }}
    </template>

    <template #quantity="{ item }">
      {{ formatNumber(item.quantity) || '-' }}
    </template>

    <template #dateCreated="{ item: { dateCreated } }">
      {{ formatDisplayDate(dateCreated) }}
    </template>

    <template #dateReleased="{ item: { dateReleased } }">
      {{ formatDisplayDate(dateReleased) }}
    </template>

    <template #amount="{ item }">
      {{ formatNumber(item.amount) }}
      / {{ formatNumber(item.taxAmount) }}
      / {{ formatNumber(item.amountWithTax) }}
      {{ ` ${item.currencyCode}` }}
    </template>

    <template #actions="{ item: { metadata } }">
      <ir-invoice-charge-metadata :metadata="metadata" />
    </template>
  </vbt-table>
</template>

<script>
import { mapGetters } from 'vuex';

import { formatDisplayDate, formatNumber } from '@helpers';

import IrInvoiceChargeMetadata from './IrInvoiceChargeMetadata.vue';

const headers = Object.freeze([
  { text: 'Account', value: 'accountId' },
  { text: 'Order ID', value: 'orderId' },
  { text: 'Charge Code', value: 'chargeCode' },
  { text: 'Product Type', value: 'productType' },
  { text: 'Quantity', value: 'quantity' },
  { text: 'Created Date', value: 'dateCreated' },
  { text: 'Released Date', value: 'dateReleased' },
  { text: 'Amount/Tax/With Tax', value: 'amount' },
  { text: '', value: 'actions' },
]);

export default {
  name: 'IrInvoiceChargesList',

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    pageSize: {
      type: Number,
      default: 10,
    },
  },

  components: {
    IrInvoiceChargeMetadata,
  },

  data() {
    return {
      headers,
    };
  },

  computed: {
    ...mapGetters('accounts', ['accountNameByIdFromAllAccounts']),
  },

  methods: {
    formatDisplayDate,
    formatNumber,
  },
};
</script>
