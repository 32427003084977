import HTTP from '@api';

import { generateXHRError } from '@helpers';

export const InvoiceChargesApiService = {
  async getInvoiceCharges({
    accountId, orderId, startDate, endDate, pageSize = 10, pageNum = 1,
  }) {
    try {
      const { data } = await HTTP.get('invoices/charges', {
        params: {
          accountId,
          orderId,
          startDate,
          endDate,
          pageSize,
          pageNum,
        },
      });

      return data.items || [];
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
};
