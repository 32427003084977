<template>
  <v-tooltip left>
    <template #activator="{ on }">
      <span
        class="activator-label info--text"
        v-on="on"
      >
        metadata
      </span>
    </template>

    <v-layout column>
      <v-layout class="mt-1">
        <strong class="mr-2">
          Country:
        </strong>

        {{ metadata.country || '-' }}
      </v-layout>

      <v-layout class="mt-2">
        <strong class="mr-2">
          State:
        </strong>

        {{ metadata.state || '-' }}
      </v-layout>

      <v-layout class="mt-2">
        <strong class="mr-2">
          ZIP:
        </strong>

        {{ metadata.zip || '-' }}
      </v-layout>

      <v-layout class="mt-2">
        <strong class="mr-2">
          Tax Name:
        </strong>

        {{ metadata.taxName || '-' }}
      </v-layout>

      <v-layout class="mt-2">
        <strong class="mr-2">
          Tracking Number:
        </strong>

        {{ metadata.trackingNumber || '-' }}
      </v-layout>

      <v-layout class="mt-2">
        <strong class="mr-2">
          Original Currency Code:
        </strong>

        {{ metadata.originalCurrencyCode || '-' }}
      </v-layout>

      <v-layout class="mt-2">
        <strong class="mr-2">
          Currency Exchange Rate:
        </strong>

        {{ metadata.currencyExchangeRate || '-' }}
      </v-layout>

      <v-layout class="mt-2 mb-1">
        <strong class="mr-2">
          Comment:
        </strong>

        {{ metadata.comment || '-' }}
      </v-layout>
    </v-layout>
  </v-tooltip>
</template>

<script>
export default {
  name: 'IrInvoiceChargeMetadata',

  props: {
    metadata: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="css" scoped>
.activator-label {
  text-decoration: underline;
  cursor: help;
}
</style>
